<template>
    <div>
        <div class="content" style="padding-bottom:100px;">
            <router-view></router-view>
        </div>
        <footer class="fx-footer">
            <ul class="f-wrap flex">
                <li class="f-list" :class="{'active':fAct==index}" v-for="(m, index) in fArr" @click.stop="_index(index, m)">
                    <i class="hwiconfont" :class="m.icon"></i>
                    <span>{{m.name}}</span>
                    <span class="num" v-if="m.showNum && s_getCount">{{s_getCount}}</span>
                </li>
            </ul>
        </footer>
    </div>
</template>
<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                fArr: [{
                    icon: 'icon-shouye',
                    name: '首页',
                    path: '/h5'
                }, {
                    icon: 'icon-fabujishu',
                    name: '发布',
                    path: '/h5add'
                }, {
                    icon: 'icon-xiaoxi5',
                    name: '信息',
                    path: '/h5msg',
                    showNum: 1
                }, {
                    icon: 'icon-mime2',
                    name: '我的',
                    path: '/h5my',
                }],
                fAct: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            let _this = this;
            this.path = this.$router.history.current.path;
            this.fArr.forEach((v, vi) => {
                if (_this.path == v.path) {
                    _this.fAct = vi;
                }
            });
        },
        mounted() {},
        methods: {
            // ...mapMutations([]),
            _index(i, m) {
                this.fAct = i;
                this.$router.push(m.path);
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(['s_getCount'])
        },
        watch: {
            // 监听
            $route(to, from) {
                // 路径判断
                const p = to.path;
                let _this = this;
                this.fArr.forEach((v, vi) => {
                    if (p == v.path) {
                        _this.fAct = vi;
                    }
                });
            },
        }
    };
</script>

<style lang="less" scoped>
    footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 1;

        .f-wrap {
            width: 100%;
            justify-content: space-between;
        }


        .f-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25vw;
            font-size: 10px;
            color: #757575;
            position: relative;
            padding: 7px 0;

            .hwiconfont {
                font-size: 25px;
                color: #eee;
                margin-bottom: 3px;
            }

            &.active {
                color: #fd5621;

                .hwiconfont {
                    color: #fd5621;
                }
            }
        }

        .num {
            background: red;
            border-radius: 50%;
            position: absolute;
            font-size: 12px;
            min-width: 15px;
            box-sizing: border-box;
            padding: 0px 5px;
            top: 0px;
            right: calc(50% - 25px);
            color: #fff;
        }
    }
</style>